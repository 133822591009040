.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    max-width: 1400px;
    margin: 100px auto;
}

.project-section {
    width: calc(50% - 20px);
    min-width: 350px;
    margin: 10px;
    padding: 10px;
    border: 5px solid rgb(188, 183, 183, 0.2);
    border-radius: 5px;
    position: relative;
    color: rgb(188, 183, 183, 1);
}

.project-section:hover {
    color: white;
}

.projects-section-name {
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 10px;
}

.link-to-projects {
    width: 80%;
    margin: 10px;
    padding: 10px;
    background-color: rgba(56, 35, 175, 0.7);
    border: 5px solid rgb(188, 183, 183, 1);
    border-radius: 15px;
    position: relative;

    font-size: 36px;
    font-weight: 900;
    color: rgb(188, 183, 183, 1);
    text-decoration: none;
}

.link-to-projects:hover {
    margin: 5px;
    border: 10px solid rgb(255, 255, 255, 1);
    background-color: rgb(29, 21, 76);
    /* background-color: rgba(56, 35, 175, 1); */
    color: rgb(255, 255, 255);
}
