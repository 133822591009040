 :root {
   --color-primary: #191d2b;
   --color-secondary: #27AE60;
   --color-white: #FFFFFF;
   --color-black: #000;
   --color-grey0: #f8f8f8;
   --color-grey-1: #dbe1e8;
   --color-grey-2: #b2becd;
   --color-grey-3: #6c7983;
   --color-grey-4: #454e56;
   --color-grey-5: #2a2e35;
   --color-grey-6: #12181b;
   --br-sm-2: 14px;
   --box-shadow-1: 0 3px 15px rgba(0, 0, 0, .3);
   --box-shadow-2: rgba(0, 0, 0, .5);
 }

 .light-mode {
   --color-primary: #ffffff;
   --color-secondary: #ae27ae;
   --color-white: #454e56;
   --color-black: #000;
   --color-grey0: #f8f8f8;
   --color-grey-1: #6c7983;
   --color-grey-2: #6c7983;
   --color-grey-3: #6c7983;
   --color-grey-4: #454e56;
   --color-grey-5: #f8f8f8;
   --color-grey-6: #12181b;
   --br-sm-2: 14px;
   --box-shadow-1: 0 3px 15px rgba(0, 0, 0, .3);
 }

 body {
   background-color: var(--color-primary);
   font-family: "Poppins", sans-serif;
   font-size: 1.1rem;
   color: var(--color-white);
   transition: all 0.4s ease-in-out;
 }

 a {
   display: inline-block;
   text-decoration: none;
   color: inherit;
   font-family: inherit;
 }

 section {
   min-height: 100vh;
   width: 70%;
   position: absolute;
   left: 0;
   padding: 3rem 18rem;
 }

 .section {
   transform: translateY(-100%);
   transition: all 0.4s ease-in-out;
   background-color: var(--color-primary);
 }

 .sec3 {
   transform: translateY(0) scale(1);
 }

 .sec4 {
   transform: translateY(0) scale(1);
 }

 .sec5 {
   transform: translateY(0) scale(1);
 }

 .active {
   display: block;
   animation: scaleAnim 0.5s ease-in-out;
 }

 @keyframes scaleAnim {
   0% {
     transform: translateY(-100%) scaleY(0);
   }

   100% {
     transform: translateY(0) scaleY(1);
   }
 }

 .controls {
   position: fixed;
   z-index: 10;
   top: 50%;
   right: 3%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   transform: translateY(-50%);
 }

 .controls .active-btn {
   background-color: var(--color-secondary) !important;
   transition: all 0.4s ease-in-out;
 }

 .controls .active-btn i {
   color: var(--color-white) !important;
 }

 .controls .control {
   cursor: pointer;
   background-color: var(--color-grey-4);
   width: 55px;
   height: 55px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0.7rem 0;
   box-shadow: var(--box-shadow-1);
 }

 .controls .control i {
   font-size: 1.2rem;
   color: var(--color-grey-2);
   pointer-events: none;
 }


 .control-3:hover::after {
   content: "js-games";
   position: absolute;
   transform: translateX(-100%);
   white-space: nowrap;
 }

 .control-4:hover::after {
   content: "python-code";
   position: absolute;
   transform: translateX(-100%);
   white-space: nowrap;
 }

 .control-5:hover::after {
   content: "fullstack";
   position: absolute;
   transform: translateX(-100%);
   white-space: nowrap;
 }


 /*Portfolios*/
 .port-text {
   padding: 2rem 0;
   text-align: center;
 }

 .portfolios {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-gap: 2rem;
   margin-top: 3rem;
 }

 .portfolios .portfolio-item {
   position: relative;
   border-radius: 3rem;
 }

 .portfolios .portfolio-item img {
   width: 100%;
   height: 300px;
   -o-object-fit: cover;
   object-fit: cover;
   border-radius: 15px;
 }

 .portfolios .portfolio-item .hover-items {
   width: 100%;
   height: 100%;
   background-color: var(--box-shadow-2);
   position: absolute;
   left: 0;
   top: 0;
   border-radius: 15px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   opacity: 0;
   transform: scale(0);
   transition: all 0.4s ease-in-out;
 }

 .portfolios .portfolio-item .hover-items h3 {
   font-size: 1.5rem;
   color: var(--color-white);
   margin-bottom: 1.5rem;
 }

 .portfolios .portfolio-item .hover-items .icons {
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .portfolios .portfolio-item .hover-items .icons .icon {
   background-color: var(--color-primary);
   border-radius: 50%;
   width: 50px;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0 1rem;
   cursor: pointer;
   transition: all 0.4s ease-in-out;
 }

 .portfolios .portfolio-item .hover-items .icons .icon i {
   font-size: 1.5rem;
   color: var(--color-white);
   margin: 0 1rem;
 }

 .portfolios .portfolio-item .hover-items .icons .icon:hover {
   background-color: var(--color-white);
 }

 .portfolios .portfolio-item .hover-items .icons .icon:hover i {
   color: var(--color-white);
 }

 .portfolio-item:hover .hover-items {
   opacity: 1;
   transform: scale(1);
 }

 /*Independend components*/

 .main-title {
   text-align: center;
 }

 .main-title h2 {
   position: relative;
   text-transform: uppercase;
   font-size: 4rem;
   font-weight: 700;
 }

 .main-title h2 span {
   color: var(--color-secondary);
 }

 .main-title h2 .bg-text {
   position: absolute;
   top: 50%;
   left: 50%;
   color: var(--color-grey-5);
   transition: all 0.4s ease-in-out;
   z-index: -1;
   transform: translate(-50%, -50%);
   font-weight: 800;
   font-size: 6.3rem;
 }

 @media screen and (max-width: 600px) {
   .portfolios {
     grid-template-columns: 1fr;
     padding-bottom: 6rem;
   }

   .section {
     padding: 2rem 2.5rem !important;
   }

   .main-title h2 {
     font-size: 2rem;
   }

   .main-title h2 span {
     font-size: 2.5rem;
   }

   .main-title h2 .bg-text {
     font-size: 2.5rem;
   }

   .controls {
     top: auto;
     bottom: 0;
     flex-direction: row;
     justify-content: center;
     left: 50%;
     transform: translateX(-50%);
     width: 100%;
     background-color: var(--color-grey-5);
   }

   .controls .control {
     margin: 1rem 0.3rem;
   }
 }

 @media screen and (max-width: 1432px) {
   header {
     padding: 0 !important;
   }

   .section {
     padding: 7rem 11rem;
   }
 }

 @media screen and (max-width: 1070px) {
   .portfolios {
     grid-template-columns: 1fr 1fr;
   }

   .main-title h2 {
     font-size: 4rem;
   }

   .main-title h2 span {
     font-size: 4rem;
   }

   .main-title h2 .bg-text {
     font-size: 4.5rem;
   }
 }

 @media screen and (max-width: 970px) {
   header {
     padding: 0;
   }

   header .right-header {
     padding: 2rem !important;
   }

   .controls {
     top: auto;
     bottom: 0;
     flex-direction: row;
     justify-content: center;
     left: 50%;
     transform: translateX(-50%);
     width: 100%;
     background-color: var(--color-grey-5);
   }

   .controls .control {
     margin: 0.6rem 2rem !important;
   }
 }

 @media screen and (max-width: 700px) {
   .section {
     padding: 7rem 3rem;
   }

   .about-stats .progress-bars {
     grid-template-columns: 1fr;
   }

   .main-title h2 {
     font-size: 3rem;
   }

   .main-title h2 span {
     font-size: 3rem;
   }

   .main-title h2 .bg-text {
     font-size: 4rem;
   }
   .icons {
    max-width: 10px;
    display: flex;
    flex-direction: column;
   }
 }